import React, { Fragment } from 'react'
import ReactPlayer from 'react-player'

import authService from 'services/authService'

import {
  Button,
  Typography,
  Modal,
  withStyles,
  Grid,
  Paper,
  CardActions,
  WithStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { styles } from './styles'

interface IProps extends WithStyles<typeof styles> {
  help: boolean
  onClose?: () => void
}

class Movie extends React.Component<IProps> {
  state = {
    url: 'https://player.vimeo.com/external/355337371.hd.mp4?s=80154342dcf9587ff11e450345ff0712a300d1b6&profile_id=174',
    playing: true,
    modalOpen: false,
    movieClose: false,
  }

  private load = (url: string) => {
    this.setState({
      url,
    })
  }

  private onOpenModal = () => {
    this.setState({ modalOpen: true })
  }

  private onCloseModal = () => {
    this.setState({ modalOpen: false })
    if (this.props.help) {
      this.props.onClose()
    }
  }

  private handleEstouPronto = () => {
    this.onCloseModal()
    authService.setPrimeiraExecucao()
  }

  private onPlay = () => {
    this.setState({ playing: true })
  }

  private onPause = () => {
    this.setState({ playing: false })
  }

  componentDidMount() {
    if (!this.props.help) {
      if (!this.state.movieClose) {
        authService.isPrimeiraExecucao().then((isPrimeiraExecucao) => {
          isPrimeiraExecucao ? this.onOpenModal() : this.onCloseModal()
        })
      }
    } else {
      this.onOpenModal()
    }
  }

  render() {
    const { classes } = this.props
    const { url, playing, modalOpen } = this.state

    return (
      <Modal open={modalOpen}>
        <Grid
          container={true}
          justifyContent="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid item={true} className={classes.content}>
            <Paper className={classes.paper}>
              <Fragment>
                <Button
                  aria-label="close"
                  onClick={this.onCloseModal}
                  className={classes.closeButton}
                >
                  <CloseIcon />
                </Button>
                <Typography
                  variant="h4"
                  color="primary"
                  align="center"
                  className={classes.titulo}
                >
                  Vamos começar!
                </Typography>
                <Typography
                  variant="h6"
                  color="textPrimary"
                  gutterBottom={true}
                  align="center"
                  className={classes.subTitulo}
                >
                  Assista o vídeo abaixo com os primeiros passos
                </Typography>
                <div className={classes.player}>
                  <ReactPlayer
                    className="react-player"
                    controls={true}
                    width="100%"
                    height="100%"
                    url={url}
                    playing={playing}
                    onPlay={this.onPlay}
                    onPause={this.onPause}
                    muted={true}
                  />
                </div>
                <CardActions className={classes.cardAction}>
                  <Button
                    variant="contained"
                    type="button"
                    color="default"
                    style={{ flex: 0.5 }}
                    aria-label="see-later"
                    className={classes.button}
                    onClick={this.onCloseModal}
                  >
                    Assistir mais tarde
                  </Button>
                  <Button
                    variant="contained"
                    type="button"
                    color="primary"
                    style={{ flex: 0.5 }}
                    aria-label="ready-start"
                    className={classes.button}
                    onClick={this.handleEstouPronto}
                  >
                    Estou pronto
                  </Button>
                </CardActions>
              </Fragment>
            </Paper>
          </Grid>
        </Grid>
      </Modal>
    )
  }
}
export default withStyles(styles)(Movie)
