import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { NotificationService } from 'services/notificationService'

import { Snackbar } from '@material-ui/core'

interface Props {
  notificationService?: NotificationService
}

@inject('notificationService')
@observer
class Notification extends React.Component<Props> {
  public render() {
    const { notificationService } = this.props
    if (!notificationService.message) {
      return <div />
    }

    return (
      <Snackbar
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        open={!!notificationService.message}
        autoHideDuration={5000}
        onClose={this.handleClose}
        message={<span>{notificationService.message}</span>}
      />
    )
  }

  private handleClose = () => {
    this.props.notificationService.message = ''
  }
}

export default Notification
