import app from 'config/initFirebase'
import { action } from 'mobx'
import authStore from 'store/authStore'
import { compradorStore } from 'store/CompradorStore'

export class AuthService {
  public startListenFirebaseAuthChange() {
    app.auth().onAuthStateChanged(
      action(async (user: firebase.default.User) => {
        const oldUser = authStore.loggedUser
        authStore.loggedUser = user
        authStore.isCheckedAuth = true
        this.doReload({ oldUser, user })
      }),
    )
  }

  private doReload({ oldUser, user, }: { oldUser: firebase.default.User, user: firebase.default.User }) {
    // Forçar o reload ou chamar novamente a service para carregar os documentos corretos
    if (!user && oldUser) {
      // logout
      window.location.pathname = '/'
    } else if (oldUser && oldUser !== user) {
      // Se houver alteração do usuário verifica se será necessário atualizar os documentos
      window.location.pathname = '/minhasCotacoes'
    } 
  }

  public async isPrimeiraExecucao() {
    const token = await app.auth().currentUser.getIdTokenResult(true)
    return token.claims.watchedMovieEAD !== true
  }

  public async setPrimeiraExecucao() {
    await app.functions().httpsCallable('setMovieEAD')({
      uid: authStore.loggedUser.uid,
    })
  }

  public async login(email: string, password: string, stayLoggedI = false) {
    await app.auth().setPersistence(stayLoggedI ? 'local' : 'session')
    await app.auth().signInWithEmailAndPassword(email, password)
  }

  public async loginWithToken(token: string) {
    await app.auth().setPersistence('session');
    await app.auth().signInWithCustomToken(token);
  }

  public async sendPasswordResetEmail(email: string) {
    await app.auth().sendPasswordResetEmail(email)
  }

  public async verifyOobCode(oobCode: string) {
    return await app.auth().verifyPasswordResetCode(oobCode)
  }

  public async createNewPassword(oobCode: string, newPassword: string) {
    return await app.auth().confirmPasswordReset(oobCode, newPassword)
  }

  public async logOut() {
    await app.auth().signOut()
  }

  public async getAccessToken() {
    return await app.auth().currentUser.getIdToken()
  }

  public getTipoUsuario() {
    if (compradorStore.comprador) {
      return 'comprador'
    }
    return 'fornecedor'
  }
}

const authService = new AuthService()
export default authService
