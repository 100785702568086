export function clearDigitsSpecialChars(value: string): string {
  if (value) {
    const anyNonDigitRegExp = /[^0-9]/g
    return value.toString().replace(anyNonDigitRegExp, '')
  }

  return ''
}

export function formatCpfCnpj(value: string): string {
  const rawValue = clearDigitsSpecialChars(value)

  if (rawValue.length >= 14) {
    return formatCnpj(rawValue)
  } else {
    return formatCpf(rawValue)
  }
}

export function formatCpf(value: string): string {
  const rawValue = clearDigitsSpecialChars(value)

  if (rawValue.length === 11) {
    const cpfSeparator = /(\d{3})(\d{3})(\d{3})(\d{2})/
    return rawValue.replace(
      cpfSeparator,
      (regex, firstPart, secondPart, thirdPart, fouthPart) => {
        return `${firstPart}.${secondPart}.${thirdPart}-${fouthPart}`
      },
    )
  }

  return rawValue
}

export function formatCnpj(value: string): string {
  const rawValue = clearDigitsSpecialChars(value)

  if (rawValue.length === 14) {
    const cnpjSeparator = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/
    return rawValue.replace(
      cnpjSeparator,
      (regex, firstPart, secondPart, thirdPart, fouthPart, fifthPart) => {
        return `${firstPart}.${secondPart}.${thirdPart}/${fouthPart}-${fifthPart}`
      },
    )
  }

  return rawValue
}

export function formatToCurrency(value = 0): string {
  if (!value) {
    value = 0
  }
  return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

export function formatDate(value: string): string {
  const rawValue = clearDigitsSpecialChars(value)

  let newValue = rawValue.substring(0, 2)

  if (rawValue.length > 2) {
    newValue += '/' + rawValue.substring(2, 2)
  }

  if (rawValue.length > 4) {
    newValue += '/' + rawValue.substring(4, 4)
  }

  return newValue
}

export function formatToAmericanDate(value = ''): string {
  if (!value) {
    return ''
  }

  const date = value.split('/')
  const formatedDate = date[2] + '-' + date[1] + '-' + date[0]

  return formatedDate
}

export function getTheFirstLetterOfTheFullName(text = ''): string {
  if (!text) {
    return text
  }

  const splittedText = text.toUpperCase().split(' ')

  if (splittedText.length > 1) {
    return `${splittedText[0].charAt(0)}${splittedText[1].charAt(0)}`
  }

  return `${splittedText} `.slice(0, 2)
}
export function formatToBrazilianDate(value = ''): string {
  if (!value) {
    return ''
  }

  const date = value.split('-')
  const formatedDate = date[2] + '/' + date[1] + '/' + date[0]

  return formatedDate
}
