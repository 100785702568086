import app from 'config/initFirebase'
import firebase from 'firebase/app'
import { action } from 'mobx'
import { cotacaoService } from 'services/cotacaoService'
import authStore from 'store/authStore'
import { cotacaoStore } from 'store/cotacaoStore'
import { fornecedorStore } from 'store/fornecedorStore'

import { AnexoFornecedorCloud } from 'shared/common/models/anexoFornecedorCloud'
import { StatusCotacaoCloud } from 'shared/common/models/cotacaoCloud'
import { CotacaoRespostaCloud } from 'shared/common/models/cotacaoRespostaCloud'
import { FornecedorCotacaoCloud } from 'shared/common/models/fornecedorCotacaoCloud'
import { ItemCotacaoCloud } from 'shared/common/models/itemCotacaoCloud'
import { ItemCotacaoRespostaCloud } from 'shared/common/models/itemCotacaoRespostaCloud'

export class FornecedorService {
  private StatusRespostaFornecedorCloud = {
    Cotar: 0,
    NaoFornecido: 1,
    Respondido: 2,
    Reaberto: 3,
  }

  @action
  public async getFornecedor(id: string): Promise<FornecedorCotacaoCloud> {
    const uid = authStore.loggedUser.uid

    const result = await app
      .firestore()
      .collection('fornecedoresCotacoes')
      .where('uid', '==', uid)
      .where('id', '==', id)
      .limit(1)
      .get()

    if (result.empty) {
      return null
    } else {
      return result.docs[0].data() as FornecedorCotacaoCloud
    }
  }

  private popularFornecedorStore(
    fornecedor: FornecedorCotacaoCloud,
    itensCotacao: ItemCotacaoRespostaCloud[],
  ) {
    if (fornecedor.resposta) {
      const itens = [...fornecedor.resposta.itens]

      fornecedorStore.cotacaoResposta = fornecedor.resposta

      fornecedorStore.itensResposta =
        fornecedor.status === this.StatusRespostaFornecedorCloud.Cotar
          ? itensCotacao.filter(
              (item) =>
                item.idFornecedor === fornecedor.id ||
                (!item.idFornecedor &&
                  itensCotacao.filter(
                    (itemCotacao) => itemCotacao.id === item.id,
                  ).length === 1),
            )
          : itens

      itens.forEach((item) => {
        const indexItem = fornecedorStore.itensResposta.findIndex(
          (itemResposta) => itemResposta.id === item.id,
        )
        if (indexItem === -1) {
          return fornecedorStore.itensResposta.push(item)
        }
        return Object.assign(fornecedorStore.itensResposta[indexItem], item)
      })

      fornecedorStore.anexosResposta = [...fornecedor.resposta.anexos]
    }
    fornecedorStore.fornecedor = fornecedor
  }

  private mapItemCotCloudToItemCotRespostaCloud(
    itensCotacao: ItemCotacaoCloud,
    idFornecedor = '',
  ) {
    const itemCotacaoRepostaCloud: ItemCotacaoRespostaCloud = {
      id: itensCotacao.id,
      idCotacao: itensCotacao.idCotacao,
      descricao: itensCotacao.descricao,
      idItem: itensCotacao.idItem || '',
      quantidadeTotalSolicitada: itensCotacao.quantidadeTotalSolicitada,
      unidade: itensCotacao.unidade,
      idCompany: itensCotacao.idCompany,
      anexos: itensCotacao.anexos,
      idFornecedor: itensCotacao.idFornecedor || idFornecedor,
      isFornecido: true,
      marcaObrigatoria: itensCotacao.marcaObrigatoria || false,
    }
    return itemCotacaoRepostaCloud
  }

  public getMinhasCotacaoes() {
    // só pode ser executado uma vez esse método (o stream fica ativo)
    if (fornecedorStore.minhasCotacoes.length > 0) {
      return
    }

    const uid = authStore.loggedUser.uid
    app
      .firestore()
      .collection('fornecedoresCotacoes')
      .where('uid', '==', uid)
      .orderBy('resposta.dataLimite', 'desc')
      .limit(100)
      .onSnapshot((observer) => {
        if (!observer.empty) {
          const cotacoes = observer.docs.map((doc) => {
            return doc.data() as FornecedorCotacaoCloud
          })
          fornecedorStore.minhasCotacoes = cotacoes
        }
      })
  }

  public isNewAnswer() {
    return fornecedorStore.fornecedor && !fornecedorStore.cotacaoResposta
  }

  @action
  public createNewAnswer(idCotacao: string) {
    const fornecedor = {
      ...fornecedorStore.fornecedor,
      status: this.StatusRespostaFornecedorCloud.Cotar,
    }
    const cotacao = {
      ...cotacaoStore.cotacao,
      idCotacao,
      anexos: [] as AnexoFornecedorCloud[],
      itens: cotacaoStore.itens.map((item) => ({ ...item, isFornecido: true })),
      observacao: '',
    }
    fornecedorStore.fornecedor = fornecedor
    fornecedorStore.cotacaoResposta = cotacao
    fornecedorStore.itensResposta = cotacao.itens
    fornecedorStore.anexosResposta = cotacao.anexos
  }

  public async naoParticiparCotacao() {
    const fornecedor: FornecedorCotacaoCloud = {
      ...fornecedorStore.fornecedor,
      status: this.StatusRespostaFornecedorCloud.NaoFornecido,
      isSynchronized: false,
    }
    const cotacao: CotacaoRespostaCloud = {
      ...fornecedorStore.cotacaoResposta,
      observacao: '',
      valorFrete: 0,
      idCondicaoPagamento: null,
      diasEntrega: 0,
      desconto: 0,
    }
    const itensFornecedor = fornecedorStore.itensResposta.map((item) => ({
      ...item,
      isFornecido: false,
      marca: '',
      valor: 0,
      observacao: '',
      desconto: 0,
      subTotal: 0,
    }))
    fornecedorStore.fornecedor = fornecedor
    fornecedorStore.cotacaoResposta = cotacao
    fornecedorStore.itensResposta = itensFornecedor
    await this.saveCotacao()
  }

  public async enviarResposta() {
    const fornecedor = {
      ...fornecedorStore.fornecedor,
      status: this.StatusRespostaFornecedorCloud.Respondido,
      isSynchronized: false,
    }
    fornecedorStore.fornecedor = fornecedor
    await this.saveCotacao()
  }

  public async saveCotacao() {
    const cotacaoFornecedor = fornecedorStore.cotacaoResposta
    cotacaoFornecedor.itens = fornecedorStore.itensResposta
    cotacaoFornecedor.anexos = fornecedorStore.anexosResposta
    await app
      .firestore()
      .collection('fornecedoresCotacoes')
      .doc(fornecedorStore.fornecedor.id)
      .set(
        {
          resposta: cotacaoFornecedor,
          status: fornecedorStore.fornecedor.status,
          isSynchronized: fornecedorStore.fornecedor.isSynchronized || false,
          started: true,
        },
        { merge: true },
      )
  }

  public async adicionarDataVisualizacao() {
    fornecedorStore.fornecedor.dataPrimeiraVisualizacao =
      firebase.firestore.Timestamp.now()
    await app
      .firestore()
      .collection('fornecedoresCotacoes')
      .doc(fornecedorStore.fornecedor.id)
      .set(
        {
          dataPrimeiraVisualizacao:
            fornecedorStore.fornecedor.dataPrimeiraVisualizacao,
        },
        { merge: true },
      )
  }

  @action
  public clearFornecedorStore() {
    fornecedorStore.fornecedor = null
    fornecedorStore.cotacaoResposta = null
    fornecedorStore.itensResposta = []
    fornecedorStore.anexosResposta = []
  }

  public async reabrirCotacao() {
    fornecedorStore.fornecedor = {
      ...fornecedorStore.fornecedor,
      status: this.StatusRespostaFornecedorCloud.Reaberto,
    }
    await this.saveCotacao()
    fornecedorStore.fornecedor = {
      ...fornecedorStore.fornecedor,
      status: this.StatusRespostaFornecedorCloud.Cotar,
    }
  }

  public isCotacaoOpened(cotacao: CotacaoRespostaCloud) {
    return (
      cotacao.status === StatusCotacaoCloud.Aberta &&
      (cotacao.dataLimite as firebase.firestore.Timestamp).toDate() >=
        firebase.firestore.Timestamp.now().toDate()
    )
  }

  public isCurrentCotacaoOpened() {
    return this.isCotacaoOpened(fornecedorStore.cotacaoResposta)
  }

  public isCurrentCotacaoEditable() {
    return (
      fornecedorStore.fornecedor &&
      fornecedorStore.fornecedor.status ===
        this.StatusRespostaFornecedorCloud.Cotar &&
      this.isCurrentCotacaoOpened()
    )
  }

  public async loadCotacao(id: string) {
    fornecedorService.clearFornecedorStore()
    const fornecedor = await fornecedorService.getFornecedor(id)
    if (fornecedor) {
      const itensCotacao = await cotacaoService.getItens(
        fornecedor.idCotacao,
        fornecedor.idCompany,
      )
      const itemCotacaoResposta = itensCotacao.map((x) =>
        this.mapItemCotCloudToItemCotRespostaCloud(x, fornecedor.id),
      )
      this.popularFornecedorStore(fornecedor, itemCotacaoResposta)

      if (fornecedorService.isNewAnswer()) {
        // se for uma nova resposta, carregar todos os dados da cotação
        await cotacaoService.getDadosCotacao(
          fornecedorStore.fornecedor.idCotacao,
          fornecedorStore.fornecedor.idCompany,
        )
        fornecedorService.createNewAnswer(fornecedorStore.fornecedor.idCotacao)
      } else {
        // caso já exista uma resposta, carregar apenas os detalhes
        await cotacaoService.getCotacao(fornecedorStore.fornecedor.idCotacao)
        await cotacaoService.getEnderecoEntrega(
          fornecedorStore.fornecedor.idCotacao,
          fornecedorStore.fornecedor.idCompany,
        )
        await cotacaoService.getitemDetalhes(
          fornecedorStore.fornecedor.idCotacao,
          fornecedorStore.fornecedor.idCompany,
        )
        await cotacaoService.getCondicaoPagamento(
          fornecedorStore.cotacaoResposta.codigoListaCondicaoPagamento,
          fornecedorStore.fornecedor.idCompany,
          fornecedorStore.cotacaoResposta.comprador.cnpj
        )
      }
    }
  }

  /**
   *  Retorna se a origem da integração é Senior.
   */
  public clienteSenior = () => {
    if (
      fornecedorStore.cotacaoResposta.idOrigin &&
      fornecedorStore.cotacaoResposta.idOrigin !== 0
    ) {
      return true
    }
    return false
  }
}

export const fornecedorService = new FornecedorService()
