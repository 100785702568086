import { observable, action } from 'mobx'

export class NotificationService {
  @observable
  public message: string = null

  @action
  public addNotification(message: string): void {
    this.message = message
  }
}

const notificationService = new NotificationService()
export default notificationService
