import { firestore } from 'config/initFirebase'
import { action } from 'mobx'
import authStore from 'store/authStore'
import { ICompradorCloud, TStatusAtivo } from '../common/models/CompradorCloud'
import { compradorStore } from '../store/CompradorStore'

export class CompradorService {
  @action
  public async getComprador() {
    const email = authStore.loggedUser.email

    const comprador = await firestore
      .collection('compradores')
      .where('email', '==', email)
      .where('status', '==', TStatusAtivo)
      .get()

    if (!comprador.empty) {
      compradorStore.comprador = comprador.docs[0].data() as ICompradorCloud
    }
  }
}

export const compradorService = new CompradorService()
