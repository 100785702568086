import React from 'react'
import { SvgIcon } from '@material-ui/core'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

export default function CustomErrorIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M7 24L0 17V7L7 0H17L24 7V17L17 24H7ZM12 18.6333C12.3111 18.6333 12.5833 18.5167 12.8167 18.2833C13.05 18.05 13.1667 17.7778 13.1667 17.4667C13.1667 17.1556 13.05 16.8833 12.8167 16.65C12.5833 16.4167 12.3111 16.3 12 16.3C11.6889 16.3 11.4167 16.4167 11.1833 16.65C10.95 16.8833 10.8333 17.1556 10.8333 17.4667C10.8333 17.7778 10.95 18.05 11.1833 18.2833C11.4167 18.5167 11.6889 18.6333 12 18.6333ZM11 13.8333H13V5.06667H11V13.8333Z"
        fill="#ff6666"
      />
    </SvgIcon>
  )
}
