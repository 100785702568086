import * as React from 'react'
import { CircularProgress } from '@material-ui/core'

export default class Loading extends React.Component {
  public render() {
    return (
      <div style={{ height: '100vh', display: 'flex' }}>
        <CircularProgress color="primary" style={{ margin: 'auto' }} />
      </div>
    )
  }
}
