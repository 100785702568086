import Login from 'pages/login/login'
import MainLayout from 'components/mainLayout/mainLayout'
import { observer } from 'mobx-react'
import * as React from 'react'
import authStore from 'store/authStore'

import { createStyles, withStyles, WithStyles } from '@material-ui/core'
import Doodle from '../doodleDinamico/Doodle'

const styles = createStyles({
  root: {
    flex: 1,
  },
})

interface IProps extends WithStyles<typeof styles> {
  children?: React.ReactNode
}

@observer
class Main extends React.Component<IProps> {
  public render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        {authStore.loggedUser ? (
          <MainLayout>
            {this.props.children}
            <Doodle />
          </MainLayout>
        ) : (
          <Login />
        )}
      </div>
    )
  }
}

export default withStyles(styles)(Main)
