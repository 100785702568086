import { firestore } from 'config/initFirebase'
import { doodleStore } from 'store/DoodlesStore'
import { action } from 'mobx'
import { IDoodle } from 'common/models/DoodlesCloud'

export class DoodleService {
  @action
  public async getDoodles() {
    const doodle = await firestore.collection('doodles').get()

    if (!doodle.empty) {
      doodleStore.doodles = doodle.docs.map((doc) => {
        doodleStore.doodleIsOpened.push(true)
        doodleStore.naoVerDoodleNovamente.push(false)
        doodleStore.imageLoaded.push(false)

        return doc.data() as IDoodle
      })
    }
  }
}

export const doodleService = new DoodleService()
