import { observable } from 'mobx'
import { CotacaoRespostaCloud } from 'shared/common/models/cotacaoRespostaCloud'
import { ItemCotacaoRespostaCloud } from 'shared/common/models/itemCotacaoRespostaCloud'
import { AnexoFornecedorCloud } from 'shared/common/models/anexoFornecedorCloud'
import { FornecedorCotacaoCloud } from 'shared/common/models/fornecedorCotacaoCloud'

export class FornecedorStore {
  @observable
  public fornecedor: FornecedorCotacaoCloud

  @observable
  public cotacaoResposta: CotacaoRespostaCloud

  @observable
  public itensResposta: ItemCotacaoRespostaCloud[] = []

  @observable
  public anexosResposta: AnexoFornecedorCloud[] = []

  @observable
  public minhasCotacoes: FornecedorCotacaoCloud[] = []
}

export const fornecedorStore = new FornecedorStore()
