import { createTheme, MuiThemeProvider } from '@material-ui/core'
import asyncComponent from 'components/asyncComponent/asyncComponent'
import Loading from 'components/loading'
import Main from 'components/main/main'
import Notification from 'components/notification/notification'
import ToastMessage from 'components/ToastMessage/ToastMessage'
import { observer, Provider } from 'mobx-react'
import * as React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import authService from 'services/authService'
import { compradorService } from 'services/CompradorService'
import { cotacaoCompradorService } from 'services/cotacaoCompradorService'
import { dashboardService } from 'services/DashboardService'
import { doodleService } from 'services/DoodleService'
import { fornecedorService } from 'services/fornecedorService'
import notificationService from 'services/notificationService'
import authStore from 'store/authStore'
import { compradorStore } from 'store/CompradorStore'
import { cotacaoCompradorStore } from 'store/cotacaoCompradorStore'
import { cotacaoStore } from 'store/cotacaoStore'
import { dashboardStore } from 'store/DashboardStore'
import { doodleStore } from 'store/DoodlesStore'
import { fornecedorStore } from 'store/fornecedorStore'
import { minhasCotacoesFilterStore } from 'store/minhasCotacoesFilterStore'
import toastMessageService from './services/toastMessageService'

const Cotacao = asyncComponent(() => import('pages/cotacao/cotacao'))
const MinhasCotacaoes = asyncComponent(
  () => import('pages/minhasCotacoes/minhasCotacoes'),
)
const DocumentacaoV1 = asyncComponent(
  () => import('pages/documentacao/DocumentacaoV1'),
)
const DocumentacaoV2 = asyncComponent(
  () => import('pages/documentacao/DocumentacaoV2'),
)
const SenhaExpirada = asyncComponent(
  () => import('pages/senhaExpirada/senhaExpirada'),
)
const RedefinicaoDeSenha = asyncComponent(
  () => import('pages/redefinicaoDeSenha/redefinicaoDeSenha'),
)
const Dashboard = asyncComponent(() => import('pages/dashboard/dashboard'))
const AreaComprador = asyncComponent(
  () => import('pages/areaComprador/areaComprador'),
)

const theme = createTheme({
  typography: {
    fontFamily: [
      'Trebuchet MS',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '0.75rem',
      },
    },
  },
  palette: {
    primary: {
      contrastText: '#fff',
      dark: '#0479AB',
      light: '#86CFEF',
      main: '#33aadf',
    },
  },
})

@observer
class App extends React.Component {
  constructor(props: {} | Readonly<{}>) {
    super(props)
    authService.startListenFirebaseAuthChange()
  }

  private providerProps = {
    fornecedorStore,
    cotacaoStore,
    dashboardStore,
    compradorStore,
    fornecedorService,
    dashboardService,
    compradorService,
    doodleStore,
    doodleService,
    notificationService,
    toastMessageService,
    cotacaoCompradorStore,
    cotacaoCompradorService,
    minhasCotacoesFilterStore
  }

  public render() {
    return (
      <Provider {...this.providerProps}>
        <MuiThemeProvider theme={theme}>
          <Notification />
          <ToastMessage />
          {authStore.isCheckedAuth ? (
            <Router>
              <Routes>
                <Route path="/check/__/auth/" element={<SenhaExpirada />} />
                <Route
                  path="/check/__/auth/action"
                  element={<SenhaExpirada />}
                />
                <Route path="/developers/api-docs" element={<DocumentacaoV1 />} />
                <Route path="/developers/api-docs-v2" element={<DocumentacaoV2 />} />
                <Route
                  path="/redefinicao-de-senha"
                  element={<RedefinicaoDeSenha />}
                />

                <Route
                  path="/"
                  element={
                    <Main>
                      <MinhasCotacaoes />
                    </Main>
                  }
                />
                <Route
                  path="/cotacao/:id"
                  element={
                    <Main>
                      <Cotacao />
                    </Main>
                  }
                />
                <Route
                  path="/minhasCotacoes"
                  element={
                    <Main>
                      <MinhasCotacaoes />
                    </Main>
                  }
                />
                <Route
                  path="/comprador/dashboard"
                  element={
                    <Main>
                      <Dashboard />
                    </Main>
                  }
                  
                />
                <Route
                  path="/comprador/cotacoesEnviadas"
                  element={
                    <Main>
                      <AreaComprador />
                    </Main>
                  }
                />
              </Routes>
            </Router>
          ) : (
            <Loading />
          )}
        </MuiThemeProvider>
      </Provider>
    )
  }
}

export default App
