import {
  Button,
  Grid,
  Popover,
  Typography,
  withStyles,
} from '@material-ui/core'
import { WithStyles } from '@material-ui/core/styles'
import { AccountCircle } from '@material-ui/icons'
import { TAdministrador, TComprador } from 'shared/common/models/CompradorCloud'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import {
  Link,
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import authService from 'services/authService'
import { CompradorService } from 'services/CompradorService'
import { CompradorStore } from 'store/CompradorStore'
import { getTheFirstLetterOfTheFullName } from 'shared/utils/formatters'
import { ReactComponent as AreaComprador } from '../../../assets/areaComprador.svg'
import { ReactComponent as AreaFornecedor } from '../../../assets/areaFornecedor.svg'
import { ReactComponent as Logout } from '../../../assets/logout.svg'
import { styles } from './styles'

interface Props extends WithStyles<typeof styles> {
  compradorService?: CompradorService
  compradorStore?: CompradorStore
  location?: Location
  navigate?: NavigateFunction
  handleMenuClose(): void
  anchorEl?: HTMLButtonElement | null
}

function withHooks(Component: any) {
  return (props: Props) => (
    <Component {...props} location={useLocation()} navigate={useNavigate()} />
  )
}

@inject('compradorService')
@inject('compradorStore')
@observer
class ProfileMenu extends React.Component<Props> {
  async componentDidMount(): Promise<void> {
    await this.props.compradorService.getComprador()
  }

  public render() {
    return (
      <Popover
        open={Boolean(this.props.anchorEl)}
        onClose={this.props.handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid className={this.props.classes.containerMenu}>
          <Grid container justifyContent="flex-end">
            <AccountCircle
              fontSize="large"
              color="primary"
              onClick={this.props.handleMenuClose}
              cursor="pointer"
            />
          </Grid>

          <Typography className={this.props.classes.avatarProfile}>
            {getTheFirstLetterOfTheFullName(
              this.props.compradorStore.comprador.nome,
            )}
          </Typography>

          <Typography className={this.props.classes.buyerName}>
            {this.props.compradorStore.comprador &&
              this.props.compradorStore.comprador.nome}
          </Typography>

          <Typography className={this.props.classes.buyerOrganization}>
            {this.props.compradorStore.comprador &&
              this.props.compradorStore.comprador.organizacao.nome}
          </Typography>

          {this.props.compradorStore.comprador &&
          (this.props.compradorStore.comprador.tipoUsuario === TComprador ||
            this.props.compradorStore.comprador.tipoUsuario ===
              TAdministrador) &&
          (this.props.location.pathname === '/comprador/cotacoesEnviadas' ||
            this.props.location.pathname === '/comprador/dashboard') ? (
            <Link
              style={{ textDecoration: 'none' }}
              to={{ pathname: '/minhasCotacoes' }}
              className={this.props.classes.buttonChangeArea}
              replace={true}
            >
              <AreaFornecedor />
              Área do fornecedor
            </Link>
          ) : (
            <Link
              style={{ textDecoration: 'none' }}
              to={{ pathname: '/comprador/cotacoesEnviadas' }}
              className={this.props.classes.buttonChangeArea}
              replace={true}
            >
              <AreaComprador />
              Área do comprador
            </Link>
          )}
          <Button
            className={this.props.classes.logoutButton}
            onClick={this.handleLogout}
          >
            <Logout />
            Sair
          </Button>
        </Grid>
      </Popover>
    )
  }

  private handleLogout = async () => {
    await authService.logOut()
  }
}

export default withStyles(styles)(withHooks(ProfileMenu))
