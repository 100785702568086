import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import {
  LockOutlined,
  MailOutline,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons'
import * as React from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { Link, Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import LogoCotei from '../../assets/logoCotei.png'
import LogoSenior from '../../assets/logoSenior.png'
import Logo from '../../components/logo'
import analytics from '../../services/analytics'
import authService from '../../services/authService'
import notificationService from '../../services/notificationService'
import { styles } from './styles'

interface Props extends WithStyles<typeof styles> {
  location?: Location
  navigate?: NavigateFunction
}

function withHooks(Component: typeof Login) {
  return (props: Props) => (
    <Component { ...props } location={useLocation()} navigate={useNavigate()} />
  )
}

class Login extends React.Component<Props> {
  public state = {
    email: '',
    isLoading: false,
    password: '',
    stayLoggedIn: false,
    showPassword: false,
    error: false
  }

  emailRef = React.createRef<TextValidator>()
  passwordRef = React.createRef<TextValidator>()

  constructor(props: Props) {
    super(props);
    const params = new URLSearchParams(this.props.location.search);
    const token = params.get('token');
    if (token) {
      (async () => {
        await this.handleLoginWithToken(token);
      })();
    }
  }

  componentDidMount() {
    analytics.initialezeAnalytics('/login')
  }

  public render() {
    const { classes } = this.props

    return (
      <ValidatorForm onSubmit={this.handleLoginSubmit} instantValidate={false}>
        <Grid container className={classes.backgroundImage} alignItems="center">
          <Grid
            container
            item
            sm={4}
            justifyContent="center"
            alignItems="center"
            className={classes.loginPanel}
          >
            <Grid
              container
              item
              sm={12}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h4">
                Bem vindo ao{' '}
                <Logo mainColor="#33aadf" detailCotor="#b6cf38" width={90} />
              </Typography>
              <Typography className={classes.subtitle}>
                Faça login para continuar
              </Typography>
              <Grid container item sm={8}>
                <Grid container className={classes.gridInput} item sm={12}>
                  <TextValidator
                    error={this.state.error}
                    label="Login"
                    fullWidth
                    value={this.state.email || ''}
                    onChange={this.handleEmailChange}
                    placeholder="e-mail"
                    type="email"
                    variant="outlined"
                    autoFocus={true}
                    className={`${classes.input} ${classes.marginBottom32}`}
                    InputProps={{
                      'aria-label': 'e-mail',
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutline className={classes.disabledColor} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <span />
                        </InputAdornment>
                      ),
                    }}
                    style={{ width: '100%' }}
                    validators={['required', 'isEmail']}
                    errorMessages={['Campo obrigatório', 'E-mail inválido']}
                    onBlur={this.handleEmailBlur}
                    ref={
                      this.emailRef as React.LegacyRef<TextValidator> &
                        React.Ref<HTMLDivElement>
                    }
                    name="email"
                  />
                </Grid>
                <Grid container item sm={12} className={classes.gridInput}>
                  <TextValidator
                    fullWidth
                    error={this.state.error}
                    label="Senha"
                    value={this.state.password || ''}
                    placeholder="senha"
                    onChange={this.handlePasswordChange}
                    className={`${classes.input}`}
                    type={this.state.showPassword ? 'text' : 'password'}
                    variant="outlined"
                    InputProps={{
                      'aria-label': 'password',
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOutlined className={classes.disabledColor} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={this.handleShowPassword}
                            aria-label={
                              this.state.showPassword
                                ? 'Ocultar senha'
                                : 'Mostrar senha'
                            }
                          >
                            {this.state.showPassword ? (
                              <Visibility className={classes.disabledColor} />
                            ) : (
                              <VisibilityOff
                                className={classes.disabledColor}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    validators={['required']}
                    errorMessages={['Campo obrigatório']}
                    onBlur={this.handlePasswordBlur}
                    ref={
                      this.passwordRef as React.LegacyRef<TextValidator> &
                        React.Ref<HTMLDivElement>
                    }
                    name="password"
                  />
                </Grid>
              </Grid>
              <Grid container item sm={8} justifyContent="flex-start">
                <FormControlLabel
                  style={{ paddingRight: '26px' }}
                  control={
                    <Checkbox
                      checked={this.state.stayLoggedIn}
                      onChange={this.handleStayLoggedIn}
                      color="primary"
                      aria-label="Permanecer conectado"
                    />
                  }
                  label="Permanecer conectado"
                />
                {this.renderBotaoEsqueceuSenha()}
              </Grid>
              <Grid container item sm={8} justifyContent="center">
                <Grid container item sm={12}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={this.state.isLoading}
                    className={`${classes.buttonEntrar} ${classes.marginTop32}`}
                    aria-label="entrar"
                  >
                    {this.state.isLoading && (
                      <CircularProgress size={25} thickness={2} />
                    )}
                    Entrar
                  </Button>
                </Grid>
                <h4 className={classes.textError}>
                  {!this.state.error
                    ? ''
                    : 'E-mail ou senha incorretos. Tente novamente.'}
                </h4>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            sm={8}
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classes.logoCotei}
          >
            <Grid
              container
              item
              sm={12}
              direction="column"
              alignItems="center"
              justifyContent="flex-end"
              className={classes.gridLogoCotei}
            >
              <img src={LogoCotei} title="Cotei" alt="Cotei" width={455} />
              <Typography variant="h4" className={classes.subtitleLogoCotei}>
                Tornando as cotações mais fáceis para todos.
              </Typography>
            </Grid>
            <Grid
              container
              item
              sm={12}
              direction="column"
              className={classes.gridLogoSenior}
            >
              <img
                src={LogoSenior}
                title="Senior"
                alt="Senior"
                className={classes.logoSenior}
              />
            </Grid>
          </Grid>
        </Grid>
      </ValidatorForm>
    )
  }

  private handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value })
  }

  private handleEmailBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    ;(this.emailRef.current as any).validate(event.target.value)
  }

  private handlePasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.setState({ password: event.target.value })
  }

  private handlePasswordBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    ;(this.passwordRef.current as any).validate(event.target.value)
  }

  private handleLoginSubmit = async () => {
    try {
      this.setState({ isLoading: true })
      const { email, password, stayLoggedIn } = this.state
      const teste = await authService.login(email, password, stayLoggedIn)
      console.log(teste)
    } catch (error) {
      if (error.code === 'auth/user-not-found') {
        this.setState({ error: true })
      } else {
        this.setState({ error: true })
      }
    }
    this.setState({ isLoading: false })
  }

  private handleLoginWithToken = async (token: string) => {
    try {
      this.setState({ isLoading: true });
      await authService.loginWithToken(token);
      this.props.navigate('/comprador/dashboard');
    }
    catch (error) {
      this.props.navigate('/');
    }
    finally {
      this.setState({ isLoading: false });
    }
  }

  private handleEsqueceuSenha = async () => {
    try {
      if (!this.state.email) {
        notificationService.addNotification('Informe o e-mail')
        return
      }
      await authService.sendPasswordResetEmail(this.state.email)
      notificationService.addNotification(
        'Favor verificar sua caixa de e-mail.',
      )
    } catch (error) {
      notificationService.addNotification(
        'Erro ao tentar enviar o e-mail para resetar a senha',
      )
    }
  }

  private handleStayLoggedIn = () => {
    this.setState({ stayLoggedIn: !this.state.stayLoggedIn })
  }

  private handleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  private renderBotaoEsqueceuSenha = () => {
    return (
      <Link
        to="/redefinicao-de-senha"
        style={{
          textDecoration: 'none',
          transform: 'translateY(13px)',
          color: '#33AADF',
          fontFamily: 'Trebuchet MS',
        }}
      >
        Esqueceu sua senha?
      </Link>
    )
  }
}

export default withStyles(styles)(withHooks(Login))
