import axios, { AxiosResponse } from 'axios'
import { action } from 'mobx'
import { dashboardStore } from 'store/DashboardStore'
import authSerive from '../services/authService'
import notificationService from 'services/notificationService'
import {
  IQuotesSent,
  ISuppliersQuoted,
  IItemsQuoted,
  IQuotedResponseTime,
  IQueriesByQuotes,
  IAverageQuoteDiscount,
  ITopRatedSuppliers,
  ILeastResponsiveSuppliers,
  ISuppliersWithBetterDeliveries,
  IQuotationAnswer,
  IMoreResponsiveSuppliers,
} from '../common/models/DashboardCloud'
export class DashboardService {
  private baseApi = axios.create({
    baseURL:
      'https://us-central1-mega-coletapreco.cloudfunctions.net/webAPISubcrib/api/v1/',
  })

  @action
  public async getQuotesSent() {
    try {
      dashboardStore.quotesSent = {} as IQuotesSent
      dashboardStore.isDashboardLoading.quotesSent = true

      const responseSentQuotes = await this.baseApi.get(
        `quotessent?fromDate=${dashboardStore.fromDate}&toDate=${dashboardStore.toDate}`,
        {
          headers: {
            Authorization: await authSerive.getAccessToken(),
          },
        },
      )

      dashboardStore.quotesSent = responseSentQuotes.data
    } catch (error) {
      dashboardStore.quotesSent = null
    } finally {
      dashboardStore.isDashboardLoading.quotesSent = false
    }
  }

  @action
  public async getSuppliersQuoted() {
    try {
      dashboardStore.suppliersQuoted = {} as ISuppliersQuoted
      dashboardStore.isDashboardLoading.suppliersQuoted = true

      const responseSuppliersQuoted = await this.baseApi.get(
        `quotedsuppliers?fromDate=${dashboardStore.fromDate}&toDate=${dashboardStore.toDate}`,
        {
          headers: {
            Authorization: await authSerive.getAccessToken(),
          },
        },
      )

      dashboardStore.suppliersQuoted = responseSuppliersQuoted.data
    } catch (error) {
      dashboardStore.suppliersQuoted = null
    } finally {
      dashboardStore.isDashboardLoading.suppliersQuoted = false
    }
  }

  @action
  public async getItemsQuoted() {
    try {
      dashboardStore.itemsQuoted = {} as IItemsQuoted
      dashboardStore.isDashboardLoading.itemsQuoted = true

      const responseItemsQuoted = await this.baseApi.get(
        `quoteditems?fromDate=${dashboardStore.fromDate}&toDate=${dashboardStore.toDate}`,
        {
          headers: {
            Authorization: await authSerive.getAccessToken(),
          },
        },
      )

      dashboardStore.itemsQuoted = responseItemsQuoted.data
    } catch (error) {
      dashboardStore.itemsQuoted = null
    } finally {
      dashboardStore.isDashboardLoading.itemsQuoted = false
    }
  }

  @action
  public async getQuotedResponseTime() {
    try {
      dashboardStore.quotedResponseTime = {} as IQuotedResponseTime
      dashboardStore.isDashboardLoading.quotedResponseTime = true

      const responseQuotedResponseTime = await this.baseApi.get(
        `quotedresponsetime?fromDate=${dashboardStore.fromDate}&toDate=${dashboardStore.toDate}`,
        {
          headers: {
            Authorization: await authSerive.getAccessToken(),
          },
        },
      )

      dashboardStore.quotedResponseTime = responseQuotedResponseTime.data
    } catch (error) {
      dashboardStore.quotedResponseTime = null
    } finally {
      dashboardStore.isDashboardLoading.quotedResponseTime = false
    }
  }

  @action
  public async getQueriesByQuotes() {
    try {
      dashboardStore.queriesByQuotes = {} as IQueriesByQuotes
      dashboardStore.isDashboardLoading.queriesByQuotes = true

      const responseQueriesByQuotes = await this.baseApi.get(
        `queriesbyquotation?fromDate=${dashboardStore.fromDate}&toDate=${dashboardStore.toDate}`,
        {
          headers: {
            Authorization: await authSerive.getAccessToken(),
          },
        },
      )

      dashboardStore.queriesByQuotes = responseQueriesByQuotes.data
    } catch (error) {
      dashboardStore.queriesByQuotes = null
    } finally {
      dashboardStore.isDashboardLoading.queriesByQuotes = false
    }
  }

  @action
  public async getAverageQuoteDiscount() {
    try {
      dashboardStore.averageQuoteDiscount = {} as IAverageQuoteDiscount
      dashboardStore.isDashboardLoading.averageQuoteDiscount = true

      const responseAverageQuoteDiscount = await this.baseApi.get(
        `averagequotediscount?fromDate=${dashboardStore.fromDate}&toDate=${dashboardStore.toDate}`,
        {
          headers: {
            Authorization: await authSerive.getAccessToken(),
          },
        },
      )

      dashboardStore.averageQuoteDiscount = responseAverageQuoteDiscount.data
    } catch (error) {
      dashboardStore.averageQuoteDiscount = null
    } finally {
      dashboardStore.isDashboardLoading.averageQuoteDiscount = false
    }
  }

  @action
  public async getTopRatedSuppliers() {
    try {
      dashboardStore.topRatedSuppliers = [] as ITopRatedSuppliers[]
      dashboardStore.isDashboardLoading.topRatedSuppliers = true

      const responseTopRatedSuppliers = await this.baseApi.get(
        `topratedsuppliers?fromDate=${dashboardStore.fromDate}&toDate=${dashboardStore.toDate}`,
        {
          headers: {
            Authorization: await authSerive.getAccessToken(),
          },
        },
      )

      dashboardStore.topRatedSuppliers = responseTopRatedSuppliers.data
    } catch (error) {
      dashboardStore.topRatedSuppliers = null
    } finally {
      dashboardStore.isDashboardLoading.topRatedSuppliers = false
    }
  }

  @action
  public async getLeastResponsiveSuppliers() {
    try {
      dashboardStore.leastResponsiveSuppliers =
        [] as ILeastResponsiveSuppliers[]
      dashboardStore.isDashboardLoading.leastResponsiveSuppliers = true

      const responseLeastResponsiveSuppliers = await this.baseApi.get(
        `leastresponsivesuppliers?fromDate=${dashboardStore.fromDate}&toDate=${dashboardStore.toDate}`,
        {
          headers: {
            Authorization: await authSerive.getAccessToken(),
          },
        },
      )
      dashboardStore.leastResponsiveSuppliers =
        responseLeastResponsiveSuppliers.data
    } catch (error) {
      dashboardStore.leastResponsiveSuppliers = null
    } finally {
      dashboardStore.isDashboardLoading.leastResponsiveSuppliers = false
    }
  }

  @action
  public async getSuppliersWithBetterDeliveries() {
    try {
      dashboardStore.suppliersWithBetterDeliveries =
        [] as ISuppliersWithBetterDeliveries[]
      dashboardStore.isDashboardLoading.suppliersWithBetterDeliveries = true

      const responseSuppliersWithBetterDeliveries = await this.baseApi.get(
        `supplierswithbetterdeliveries?fromDate=${dashboardStore.fromDate}&toDate=${dashboardStore.toDate}`,
        {
          headers: {
            Authorization: await authSerive.getAccessToken(),
          },
        },
      )

      dashboardStore.suppliersWithBetterDeliveries =
        responseSuppliersWithBetterDeliveries.data
    } catch (error) {
      dashboardStore.suppliersWithBetterDeliveries = null
    } finally {
      dashboardStore.isDashboardLoading.suppliersWithBetterDeliveries = false
    }
  }

  @action
  public async getQuotationAnswer() {
    try {
      dashboardStore.quotationAnswer = {} as IQuotationAnswer
      dashboardStore.isDashboardLoading.quotationAnswer = true

      const responseQuotationAnswer = await this.baseApi.get(
        `quotationanswer?fromDate=${dashboardStore.fromDate}&toDate=${dashboardStore.toDate}`,
        {
          headers: {
            Authorization: await authSerive.getAccessToken(),
          },
        },
      )

      dashboardStore.quotationAnswer = responseQuotationAnswer.data
    } catch (error) {
      dashboardStore.quotationAnswer = null
    } finally {
      dashboardStore.isDashboardLoading.quotationAnswer = false
    }
  }

  @action
  public async getMoreResponsiveSuppliers() {
    try {
      dashboardStore.moreResponsiveSuppliers = [] as IMoreResponsiveSuppliers[]
      dashboardStore.isDashboardLoading.moreResponsiveSuppliers = true

      const responseMoreResponsiveSuppliers = await this.baseApi.get(
        `moreresponsivesuppliers?fromDate=${dashboardStore.fromDate}&toDate=${dashboardStore.toDate}`,
        {
          headers: {
            Authorization: await authSerive.getAccessToken(),
          },
        },
      )

      dashboardStore.moreResponsiveSuppliers =
        responseMoreResponsiveSuppliers.data
    } catch (error) {
      dashboardStore.moreResponsiveSuppliers = null
    } finally {
      dashboardStore.isDashboardLoading.moreResponsiveSuppliers = false
    }
  }

  public async downloadExcel() {
    try {
      dashboardStore.isDashboardLoading.downloadExcel = true
      const responseHistoricoDeUso = await this.baseApi.get(
        `historicodeusodashboard?fromDate=${dashboardStore.fromDate}&toDate=${dashboardStore.toDate}`,
        {
          headers: {
            Authorization: await authSerive.getAccessToken(),
          },
        },
      )

      window.open(responseHistoricoDeUso.data.downloadLink)
    } catch (error) {
      notificationService.addNotification('Não foi concluir o download')
    } finally {
      dashboardStore.isDashboardLoading.downloadExcel = false
    }
  }
}
export const dashboardService = new DashboardService()
