import createStyles from '@material-ui/core/styles/createStyles'

export const styles = () =>
  createStyles({
    navLink: {
      textDecoration: 'none',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      padding: '8px 20px',
      borderRadius: '8px',
      marginLeft: '16px',
    },
    iconNavLink: {
      width: '30px',
      marginRight: '8px',
      fill: '#FFFFFF',
      stroke: '#33AADF',
    },
    textNavLink: {
      fontWeight: 'bold',
      fontFamily: 'Roboto, sans-serif',
      color: '#FFFFFF',
    },
    navContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    buttonChangeArea: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '8px 12px',
      gap: '8px',
      background: '#FFFFFF',
      color: '#33AADF',
      marginBottom: '16px',
      border: '1px solid #33AADF',
      borderRadius: '8px',
      boxShadow: 'none',

      fontFamily: 'Trebuchet MS',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '23px',
      letterSpacing: '0.01em',

      transition: '0.3s',
      '& svg': {
        fill: '#33AADF',
        transition: '0.3s',
      },
      '&:hover': {
        background: '#33AADF',
        color: '#FFFFFF',
        '& svg': {
          fill: '#FFFFFF',
        },
      },
    },
    logoutButton: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '16px',
      fontFamily: 'Trebuchet MS',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '23px',
      background: 'none',
      color: '#33AADF',
      transition: '.3s',
      '&:hover': {
        background: 'none',
        textDecoration: 'underline',
        filter: 'brightness(.7)',
      },
    },
    avatarProfile: {
      display: 'flex',
      width: '68px',
      height: '68px',
      padding: '18px',
      background: '#33AADF',
      color: '#FFFFFF',
      fontSize: '48px',
      borderRadius: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '16px',
      lineHeight: 1,
    },
    buyerName: {
      fontSize: '20px',
      fontWeight: 400,
      textAlign: 'center',
    },
    buyerOrganization: {
      fontSize: '20px',
      fontWeight: 400,
      color: '#979797',
      marginBottom: '18px',
      wordBreak: 'break-word',
      textAlign: 'center',
    },
    containerMenu: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      maxWidth: '250px',
    },
    paperStyle: {
      padding: '16px 0px',
    },
    maxZIndex: {
      zIndex: 99999,
    },
  })
