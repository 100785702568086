import { observable } from 'mobx'

import { CondicaoPagamentoCloud } from 'shared/common/models/condicaoPagamentoCloud'
import { CotacaoCloud } from 'shared/common/models/cotacaoCloud'
import { EnderecoCotacaoCloud } from 'shared/common/models/enderecoCotacaoCloud'
import { ItemCotacaoCloud } from 'shared/common/models/itemCotacaoCloud'
import { ItemDetalheCotacaoCloud } from 'shared/common/models/itemDetalheCotacaoCloud'

export class CotacaoStore {
  @observable
  public cotacao: CotacaoCloud

  @observable
  public itens: ItemCotacaoCloud[] = []

  @observable
  public itensDetalhe: ItemDetalheCotacaoCloud[] = []

  @observable
  public enderecoCotacao: EnderecoCotacaoCloud[] = []

  @observable
  public condicaoPagamento: CondicaoPagamentoCloud[]
}

export const cotacaoStore = new CotacaoStore()
