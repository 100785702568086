import * as React from 'react'

import { WithStyles, withStyles } from '@material-ui/core'

import ToolBar from 'components/toolbar/toolbar'
import { styles } from './styles'

interface Props extends WithStyles<typeof styles> {
  children?: React.ReactNode
}

class MainLayout extends React.Component<Props> {
  public render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <header>
          <ToolBar />
        </header>
        <main className={classes.container}>{this.props.children}</main>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(MainLayout)
