import { IDoodle } from 'shared/common/models/DoodlesCloud'
import { observable } from 'mobx'

export class DoodleStore {
  @observable
  public doodles: IDoodle[] = []

  @observable
  public doodleIsOpened: boolean[] = []

  @observable
  public naoVerDoodleNovamente: boolean[] = []

  @observable
  public imageLoaded: boolean[] = []
}

export const doodleStore = new DoodleStore()
