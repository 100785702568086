import * as firebase from 'firebase';

export const TComprador = 'C'
export const TDesenvolvedor = 'D'
export const TAdministrador = 'A'

export const TStatusAtivo = 'A';
export const TStatusInativo = 'I';

export interface IFiliais {
  codigo: string
  nome: string
  cnpj?: string
}

export interface IOrganizacao {
  cnpj: string
  nome: string
}

export interface ICompradorCloud {
  tipoUsuario: typeof TAdministrador | typeof TDesenvolvedor | typeof TComprador
  organizacao: IOrganizacao
  filiais: IFiliais[]
  nome: string
  email: string
  idCompany: string
  createdAt: firebase.default.firestore.Timestamp
  updatedAt: firebase.default.firestore.Timestamp
  status: typeof TStatusAtivo | typeof TStatusInativo
  id: string
  uid?: string // Código do usuário no FirebaseAuth
}
