import { createStyles } from '@material-ui/core'

export const styles = () =>
  createStyles({
    navLink: {
      textDecoration: 'none',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      padding: '8px 20px',
      borderRadius: '8px',
      marginLeft: '16px',
    },
    iconNavLink: {
      width: '30px',
      marginRight: '8px',
      fill: '#FFFFFF',
      stroke: '#33AADF',
    },
    textNavLink: {
      fontWeight: 'bold',
      fontFamily: 'Roboto, sans-serif',
      color: '#FFFFFF',
    },
    navContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    buttonChangeArea: {
      background: '#FFFFFF',
      color: '#33AADF',
      marginBottom: '16px',
      border: '1px solid #33AADF',
      borderRadius: '8px',
      boxShadow: 'none',
      fontSize: '16px',
      fontWeight: 'bold',
      transition: '0.3s',
      '& svg': {
        fill: '#33AADF',
        transition: '0.3s',
      },
      '&:hover': {
        background: '#33AADF',
        color: '#FFFFFF',
        '& svg': {
          fill: '#FFFFFF',
        },
      },
    },
    paperStyle: {
      padding: '16px 0px',
    },
    maxZIndex: {
      zIndex: 99999,
    },
  })
