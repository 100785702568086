import { createStyles, Theme } from '@material-ui/core'

export const styles = (theme: Theme) =>
  createStyles({
    content: {
      maxHeight: '50%',
      maxWidth: '50%',
    },
    root: {
      minHeight: '100vh',
      margin: 0,
      padding: theme.spacing(1),
    },
    titulo: {
      paddingTop: '10px',
      marginLeft: '5px',
      paddingLeft: '50px',
      textShadow: '2px 2px rgba(0,0,0,0.1)',
      lineHeight: '1.6',
    },
    subTitulo: {
      lineHeight: '1.6',
    },
    button: {
      marginTop: '3px',
      marginBottom: '5px',
    },
    closeButton: {
      lineHeight: '1',
      top: 10,
      float: 'right',
      color: theme.palette.grey[500],
    },
    cardAction: {
      justifyContent: 'center',
      paddingLeft: '4%',
      paddingRight: '4%',
    },
    player: {
      paddingLeft: '4%',
      paddingRight: '4%',
    },
    paper: {
      backgroundColor: '#FFFAFA',
      borderRadius: '15px',
    },
  })
