import { createStyles, Theme } from '@material-ui/core/styles'

export const styles = (theme: Theme) =>
  createStyles({
    toastContainer: {
      padding: '0px 16px',
      background: '#FBFBFB',
      borderWidth: '1px 1px 5px 1px',
      borderStyle: 'solid',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
      borderRadius: '8px 8px 0px 0px',
    },
    success: {
      borderColor: '#5dcc52',
    },
    error: {
      borderColor: '#ff6666',
    },
    info: {
      borderColor: '#33aadf',
    },
    warning: {
      borderColor: '#FFA733',
    },
    icon: {
      fontSize: 32,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    toastTitle: {
      fontFamily: 'Trebuchet MS',
      fontSize: '16px',
      fontWeight: 700,
      color: '#1A1A1A',
    },
    toastMessage: {
      fontFamily: 'Trebuchet MS',
      fontSize: '14px',
      fontWeight: 400,
      color: '#1A1A1A',
    },
    close: {
      color: '#1A1A1A',
    },
    snackBottom: {
      bottom: 0,
    },
  })
