import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { WithStyles } from '@material-ui/styles'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import Skeleton from 'react-loading-skeleton'
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom'

import { DoodleService } from 'services/DoodleService'
import authService from 'services/authService'
import { IDoodle, ITextoDoodle } from 'shared/common/models/DoodlesCloud'
import { DoodleStore } from 'store/DoodlesStore'
import authStore from 'store/authStore'
import { styles } from './styles'

interface Props extends WithStyles<typeof styles> {
  location?: Location
  navigate?: NavigateFunction
  doodleStore?: DoodleStore
  doodleService?: DoodleService
}

function withHooks(Component: any) {
  return (props: Props) => (
    <Component {...props} location={useLocation()} navigate={useNavigate()} />
  )
}

@inject('doodleStore')
@inject('doodleService')
@observer
class Doodle extends React.Component<Props> {
  private doodleStore = this.props.doodleStore
  private doodleService = this.props.doodleService

  componentDidMount() {
    this.doodleService.getDoodles()
  }

  public render() {
    if (!this.doodleStore.doodles.length) {
      return <></>
    }
    const { classes } = this.props
    return this.doodleStore.doodles.map((doodle, index) => {
      return (
        <Dialog
          key={doodle.nome + index}
          open={this.doodleIsOpen(doodle, index)}
          maxWidth="lg"
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <Grid container item sm={12} direction="row">
              <Grid
                container
                item
                sm={5}
                justifyContent="center"
                alignItems="center"
                alignContent="center"
              >
                {!this.doodleStore.imageLoaded[index] && (
                  <Skeleton count={1} width="468px" height="289px" />
                )}
                <img
                  src={doodle.imagem.link}
                  alt={doodle.imagem.textoAlternativo}
                  onLoad={() => (this.doodleStore.imageLoaded[index] = true)}
                />
              </Grid>
              <Grid
                container
                item
                sm={7}
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
              >
                <Grid container item sm={10} justifyContent="center">
                  {doodle.textos.map((texto, index) => {
                    return this.getTextoStyled(texto, index)
                  })}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <FormGroup row style={{ justifyContent: 'flex-end' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.doodleStore.naoVerDoodleNovamente[index]}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      this.onChangVisualizarNovamente(index, event)
                    }}
                    color="primary"
                  />
                }
                className={classes.checkBoxLabel}
                label="Não desejo ver essa mensagem novamente"
              />
            </FormGroup>
            <Button
              onClick={() => {
                this.handleCloseDoodle(index, doodle)
              }}
              className={`${classes.button}`}
              variant="outlined"
              color="primary"
            >
              OK
            </Button>
            {doodle.acaoExtra && (
              <Button
                variant="contained"
                onClick={() => {
                  this.handleClickAcaoExtra(index, doodle)
                }}
                className={`${classes.button} ${classes.buttonSecondary}`}
                color="primary"
              >
                {doodle.acaoExtra.tituloBotao}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )
    })
  }

  private getTextoStyled = (texto: ITextoDoodle, index: number) => {
    if (texto.tipo === 'titulo') {
      return (
        <Typography
          key={texto.texto + index}
          variant="h4"
          style={{
            color: texto.cor ? texto.cor : '#33AADF',
            fontWeight: texto.fontWeight ? texto.fontWeight : 'bold',
          }}
        >
          {texto.texto}
        </Typography>
      )
    }
    return (
      <Typography
        key={texto.texto + index}
        align="center"
        variant="h6"
        style={{
          color: texto.cor ? texto.cor : '#575757',
          fontWeight: texto.fontWeight ? texto.fontWeight : 'normal',
        }}
      >
        {texto.texto}
      </Typography>
    )
  }

  private doodleIsOpen = (doodle: IDoodle, index: number) => {
    if (
      (doodle.usuarioTeste === authStore.loggedUser.email ||
        !doodle.usuarioTeste) &&
      (doodle.tipoUsuario === authService.getTipoUsuario() ||
        !doodle.tipoUsuario)
    ) {
      const pathName = this.props.location.pathname

      const { doodleIsOpened } = this.doodleStore
      if (
        doodleIsOpened[index] === false ||
        !doodle.rotasDisponiveis.filter((rota) => rota.startsWith(pathName))
          .length
      ) {
        return false
      }

      return !this.getLocalStorage(doodle.nome)
    }
    return false
  }

  private setLocalStorage = (doodleName = '') => {
    localStorage.setItem(`@cotei/doodle/${doodleName}`, 'true')
  }

  public getLocalStorage = (doodleName = '') => {
    const itemFromLocalStorage = localStorage.getItem(
      `@cotei/doodle/${doodleName}`,
    )
    if (itemFromLocalStorage) {
      return true
    }
    return false
  }

  private handleClickAcaoExtra = (index: number, doodle: IDoodle) => {
    this.handleCloseDoodle(index, doodle)
    if (doodle.acaoExtra.link.startsWith('http')) {
      window.location.href = doodle.acaoExtra.link
    } else {
      this.props.navigate(doodle.acaoExtra.link)
    }
  }

  public handleCloseDoodle = (index: number, doodle: IDoodle) => {
    if (this.doodleStore.naoVerDoodleNovamente[index]) {
      this.setLocalStorage(doodle.nome)
    }
    this.doodleStore.doodleIsOpened[index] = false
  }

  public onChangVisualizarNovamente = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.doodleStore.naoVerDoodleNovamente[index] = event.target.checked
  }
}

export default withStyles(styles)(withHooks(Doodle))
