import { Grid, Snackbar, SnackbarContent, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { inject, observer } from 'mobx-react'
import React from 'react'

import { ToastMessageService } from '../../services/toastMessageService'
import CustomErrorIcon from './customIcons/CustomErrorIcon'
import CustomInfoIcon from './customIcons/CustomInfoIcon'
import CustomSuccessIcon from './customIcons/CustomSuccessIcon'
import CustomWarningIcon from './customIcons/CustomWarningIcon'
import { styles } from './styles'

interface IToastMessageProps extends WithStyles<typeof styles> {
  toastMessageService?: ToastMessageService
}

@inject('toastMessageService')
@observer
class ToastMessage extends React.Component<IToastMessageProps> {
  private toastMessageService = this.props.toastMessageService

  public render() {
    const { classes } = this.props

    const variantIcon = {
      success: (
        <CustomSuccessIcon
          className={`${classes.icon} ${classes.iconVariant}`}
        />
      ),
      warning: (
        <CustomWarningIcon
          className={`${classes.icon} ${classes.iconVariant}`}
        />
      ),
      error: (
        <CustomErrorIcon className={`${classes.icon} ${classes.iconVariant}`} />
      ),
      info: (
        <CustomInfoIcon className={`${classes.icon} ${classes.iconVariant}`} />
      ),
    }

    const title = {
      success: 'SUCESSO',
      warning: 'ATENÇÃO',
      error: 'ERRO',
      info: 'INFO',
    }

    return (
      <>
        <Snackbar
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          className={classes.snackBottom}
          TransitionComponent={(props) => <Slide direction="up" {...props} />}
          open={this.toastMessageService.isOpen}
          autoHideDuration={this.toastMessageService.duration}
          onClose={this.handleClose}
        >
          <SnackbarContent
            className={`${classes.toastContainer} ${
              classes[this.toastMessageService.variant]
            }`}
            aria-describedby="client-snackbar"
            message={
              <Grid direction="row" container alignItems="center" wrap="nowrap">
                {variantIcon[this.toastMessageService.variant]}

                <Grid
                  id="client-snackbar"
                  container
                  direction="column"
                  alignItems="flex-start"
                >
                  <Typography className={classes.toastTitle}>
                    {title[this.toastMessageService.variant]}
                  </Typography>
                  <Typography className={classes.toastMessage}>
                    {this.toastMessageService.message}
                  </Typography>
                </Grid>

                <IconButton
                  key="close-toast"
                  aria-label="close-toast"
                  className={classes.close}
                  onClick={this.handleClose}
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>
              </Grid>
            }
          />
        </Snackbar>
      </>
    )
  }

  private handleClose = () => {
    this.toastMessageService.isOpen = false
    this.toastMessageService.message = ''
  }
}

export default withStyles(styles)(ToastMessage)
