import { createStyles, Theme } from '@material-ui/core/styles'
import BackgroundImage from 'assets/backgroundImage.png'

export const styles = (theme: Theme) =>
  createStyles({
    backgroundImage: {
      backgroundImage: `url("${BackgroundImage}")`,
      backgroundSize: 'cover',
      backgroundPosition: 'right top',
      backgroundPositionX: '50%',
    },
    loginPanel: {
      background: '#FFFFFF',
      borderRadius: '0px 40px 40px 0px',
      boxShadow: '10px 3px 6px #00000029',
      height: '100vh',
      maxWidth: '550px',
    },
    disabledColor: {
      color: '#989A9A',
    },
    logoCotei: {
      height: '100vh',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    forgetPassword: {
      textDecoration: 'none',
      transform: 'translateY(13px)',
      color: '#33AADF',
      fontFamily: 'Trebuchet MS',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    input: {
      flex: 1,
    },
    marginBottom32: {
      marginBottom: '32px',
    },
    marginTop32: {
      marginTop: '32px',
    },
    buttonEntrar: {
      flex: 1,
    },
    subtitle: {
      fontSize: '30px',
      color: '#505050',
      fontFamily: 'Roboto, sans-serif',
      marginBottom: '40px',
    },
    textError: {
      color: '#FF6666',
      fontFamily: 'Normal, sans-serif',
      paddingLeft: '26px',
    },
    gridLogoCotei: {
      alignSelf: 'flex-end',
    },
    subtitleLogoCotei: {
      color: '#FFFFFF',
      marginTop: '24px',
    },
    gridLogoSenior: {
      alignContent: 'center',
      alignSelf: 'flex-end',
      marginBottom: '56px',
    },
    logoSenior: {
      alignSelf: 'center',
    },
    gridInput: {
      '& div': {
        flex: 1,
      },
    },
  })
