import { observable, action } from 'mobx'

export class ToastMessageService {
  @observable
  public message = ''

  @observable
  public variant: 'info' | 'error' | 'success' | 'warning'

  @observable
  public duration: number

  @observable
  public isOpen = false

  @action
  public addToast(
    message: string,
    variant: typeof this.variant = 'success',
    duration = 30000,
  ): void {
    this.message = message
    this.duration = duration
    this.variant = variant
    this.isOpen = true
  }
}

const toastMessageService = new ToastMessageService()
export default toastMessageService
