import 'firebase/messaging'
import * as firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/auth'
import 'firebase/performance'
import { FornecedorStore } from 'store/fornecedorStore'
import { uuid } from 'uuidv4'

const config = {
  apiKey: 'AIzaSyB254IWiqqEDRcp29qXhAuGtmD4BZLnW5U',
  authDomain: 'mega-coletapreco.firebaseapp.com',
  databaseURL: 'https://mega-coletapreco.firebaseio.com',
  projectId: 'mega-coletapreco',
  appId: '1:59071335519:web:d38dee5f442c3544',
  storageBucket: 'mega-coletapreco.appspot.com',
  messagingSenderId: '59071335519',
}

const app = firebase.default.initializeApp(config)
export const firestore = app.firestore()
firestore.enablePersistence()
firebase.default.performance() // Habilitando o monitor de performance

export const pedirPermissaoParaReceberNotificacoes = async (
  fornecedor: FornecedorStore,
) => {
  try {
    navigator.serviceWorker.register('./firebase-messaging-sw.js')
    const messaging = app.messaging()
    const token = await messaging.getToken()
    const fornecedorData = fornecedor.minhasCotacoes[0]
    localStorage.setItem('messageId', token)
    const browserNotification = await app
      .firestore()
      .collection('browserNotification')
      .where('email', '==', fornecedorData.email)
      .get()

    if (!browserNotification.empty) {
      const data = browserNotification.docs[0].data()
      app
        .firestore()
        .doc(`browserNotification/${data.id}`)
        .update({
          tokens: [...data.tokens, token],
        })
    } else {
      const id = uuid()
      app
        .firestore()
        .collection('browserNotification')
        .doc(id)
        .set({
          email: fornecedorData.email,
          idCompany: fornecedorData.idCompany,
          tokens: [token],
          cnpjFornecedor: fornecedorData.cnpjFornecedor || '',
          nome: fornecedorData.nome,
          id,
        })
    }
    return token
  } catch (error) {
    localStorage.removeItem('messageId')
    return error
  }
}

export default app
