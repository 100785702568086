import { observable } from "mobx";

export class MinhasCotacoesFilterStore {
    @observable
    public selectedStatus: number = 9;

    @observable
    public selectedDate: number = 0;

    @observable
    public texto: string = '';
}

export const minhasCotacoesFilterStore = new MinhasCotacoesFilterStore();