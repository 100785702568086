import * as React from 'react'
import {
  AppBar,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core'
import { AccountCircle, HelpOutline } from '@material-ui/icons'
import { WithStyles } from '@material-ui/styles'
import { TAdministrador, TComprador } from 'shared/common/models/CompradorCloud'
import Logo from 'components/logo'
import { inject, observer } from 'mobx-react'
import {
  Link,
  Location,
  NavigateFunction,
  NavLink,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import authService from 'services/authService'
import { CompradorService } from 'services/CompradorService'
import { CompradorStore } from 'store/CompradorStore'
import { ReactComponent as DashboardLogo } from '../../assets/dashboard.svg'
import { ReactComponent as CotacoesCompradorLogo } from '../../assets/fact_check.svg'
import Movie from '../movie/movie'
import ProfileMenu from './components/ProfileMenu'
import { styles } from './styles'
import './styles.css'

interface IState {
  anchorEl: EventTarget | null
  menuOption: string
  movieOpen: boolean
}

interface Props extends WithStyles<typeof styles> {
  compradorService?: CompradorService
  compradorStore?: CompradorStore
  navigate?: NavigateFunction
  location?: Location
}

function withHooks(Component: any) {
  return (props: Props) => (
    <Component {...props} location={useLocation()} navigate={useNavigate()} />
  )
}
@inject('compradorService')
@inject('compradorStore')
@observer
class ToolBar extends React.Component<Props, IState> {
  public state = {
    anchorEl: null as any,
    menuOption: null as string,
    movieOpen: false,
  }

  async componentDidMount(): Promise<void> {
    await this.props.compradorService.getComprador()
  }

  public render() {
    const { classes, location } = this.props
    return (
      <React.Fragment>
        <AppBar position="sticky" color="primary">
          <Toolbar>
            <Link to={{ pathname: '/minhasCotacoes' }} replace={true}>
              <Logo width={100} mainColor="#fff" detailCotor="#b6cf38" />
            </Link>
            {this.props.compradorStore.comprador &&
              (this.props.compradorStore.comprador.tipoUsuario === TComprador ||
                this.props.compradorStore.comprador.tipoUsuario ===
                  TAdministrador) &&
              (location.pathname === '/comprador/cotacoesEnviadas' ||
                location.pathname === '/comprador/dashboard') && (
                <Grid className={classes.navContainer}>
                  <NavLink
                    className={classes.navLink}
                    to="/comprador/cotacoesEnviadas"
                    replace={true}
                  >
                    <CotacoesCompradorLogo className={classes.iconNavLink} />
                    <Typography className={classes.textNavLink}>
                      Cotações
                    </Typography>
                  </NavLink>
                  <NavLink
                    className={classes.navLink}
                    to="/comprador/dashboard"
                    replace={true}
                  >
                    <DashboardLogo className={classes.iconNavLink} />
                    <Typography className={classes.textNavLink}>
                      Dashboard
                    </Typography>
                  </NavLink>
                </Grid>
              )}
            <span style={{ flex: 1 }} />
            <IconButton
              color="inherit"
              aria-label="Ajuda"
              onClick={(event) => this.handleMenuHelpOpen(event.target)}
            >
              <HelpOutline />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="Conta do usuário"
              onClick={(event) => this.handleMenuUsuarioOpen(event.target)}
            >
              <AccountCircle />
            </IconButton>
          </Toolbar>
        </AppBar>
        {this.renderOptionMenu()}
      </React.Fragment>
    )
  }

  private renderOptionMenu() {
    switch (this.state.menuOption) {
      case 'user': {
        return this.props.compradorStore.comprador ? (
          <ProfileMenu
            handleMenuClose={this.handleMenuClose}
            anchorEl={this.state.anchorEl}
            location={this.props.location}
          />
        ) : (
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleMenuClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            {this.renderBotaoMinhasCotacoes()}
            <MenuItem onClick={this.handleLogout}>Sair</MenuItem>
          </Menu>
        )
      }
      case 'help': {
        return (
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleMenuClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            {this.renderButtonHelp()}
          </Menu>
        )
      }
      case 'movie': {
        return <Movie help={true} onClose={this.handleMovieClose.bind(this)} />
      }
      default:
        return null
    }
  }

  private renderButtonHelp = () => {
    const baseURL =
      'https://taskcenter.mega.com.br/hc/pt-br/articles/360033611334#'
    return (
      <React.Fragment>
        <MenuItem
          key="0"
          onClick={(event) => this.handleMovieOpen(event.target)}
        >
          Assista o vídeo de introdução
        </MenuItem>
        <MenuItem
          key="1"
          onClick={() => {
            window.open(baseURL + '1')
            this.setState({ anchorEl: null, menuOption: null })
          }}
        >
          Como preencher uma cotação
        </MenuItem>
        <MenuItem
          key="2"
          onClick={() => {
            window.open(baseURL + '2')
            this.setState({ anchorEl: null, menuOption: null })
          }}
        >
          Como salvar um rascunho
        </MenuItem>
        <MenuItem
          key="3"
          onClick={() => {
            window.open(baseURL + '3')
            this.setState({ anchorEl: null, menuOption: null })
          }}
        >
          Como reabrir uma cotação enviada
        </MenuItem>
        <MenuItem
          key="4"
          onClick={() => {
            window.open(baseURL + '4')
            this.setState({ anchorEl: null, menuOption: null })
          }}
        >
          Como não participar de uma cotação
        </MenuItem>
        <MenuItem
          key="5"
          onClick={() => {
            window.open(baseURL + '5')
            this.setState({ anchorEl: null, menuOption: null })
          }}
        >
          Como utilizar a tela principal minhas cotações
        </MenuItem>
      </React.Fragment>
    )
  }

  private renderBotaoMinhasCotacoes = () => {
    return (
      <MenuItem
        onClick={() => {
          this.setState({ anchorEl: null })
          this.props.navigate('/minhasCotacoes')
        }}
      >
        Minhas cotações
      </MenuItem>
    )
  }

  private handleMovieClose = () => {
    this.setState({ menuOption: null, movieOpen: false })
  }

  private handleMenuClose = () => {
    this.setState({ anchorEl: null })
  }

  private handleLogout = async () => {
    await authService.logOut()
  }

  private handleMenuUsuarioOpen = (target: EventTarget) => {
    this.setState({ anchorEl: target, menuOption: 'user', movieOpen: false })
  }

  private handleMenuHelpOpen = (target: EventTarget) => {
    this.setState({ anchorEl: target, menuOption: 'help', movieOpen: false })
  }

  private handleMovieOpen = (target: EventTarget) => {
    this.setState({ anchorEl: target, menuOption: 'movie', movieOpen: true })
  }
}

export default withStyles(styles)(withHooks(ToolBar))
