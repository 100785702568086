import * as React from 'react'

interface Props {
  width: number
  mainColor: '#fff' | '#33aadf'
  detailCotor: '#b6cf38'
}

export default class Logo extends React.Component<Props> {
  public render() {
    const { width, mainColor, detailCotor } = this.props
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 402.89 138.08"
        width={`${width}px`}
      >
        <title>Cotei</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              fill={mainColor}
              d="M378.12,90.24c-.07-.52-.07-20.52-.05-41A12.84,12.84,0,0,0,365.23,36.4h0a12.84,12.84,0,0,0-12.84,12.87l.11,45.87a50.71,50.71,0,0,0,32.88,41.33,13,13,0,0,0,17.51-12.21h0a12.89,12.89,0,0,0-8.38-12.16,25,25,0,0,1-16.39-21.86Z"
            />
            <path
              fill={mainColor}
              d="M126.53,36.42A50.82,50.82,0,0,0,75.72,87.15v.12a25.4,25.4,0,0,1-7.16,17.54,25.08,25.08,0,0,1-9.08,5.82,24.57,24.57,0,0,1-17.29-.09,25,25,0,0,1,9.9-48.32,12.7,12.7,0,0,0,12.54-7.84l.25-.61a12.61,12.61,0,0,0-11-17.26c-1-.06-2-.09-3-.09a50.83,50.83,0,0,0-18.29,98.27,50,50,0,0,0,18.29,3.39,50.45,50.45,0,0,0,37.84-16.86,50.84,50.84,0,1,0,37.84-84.8Zm17.74,68.39a25,25,0,0,1-42.6-17.54v-.12a25,25,0,0,1,42.6-17.62A25.3,25.3,0,0,1,144.27,104.81Z"
            />
            <path
              fill={mainColor}
              d="M343,78.11a50.8,50.8,0,0,0-100,0,49.75,49.75,0,0,0-.84,9A50.83,50.83,0,0,0,293.07,138a52.3,52.3,0,0,0,7.34-.52,13,13,0,0,0,9.18-19.61h0a12.83,12.83,0,0,0-12.8-6.08,24.7,24.7,0,0,1-21.26-7.11c-.46-.46-.92-1-1.34-1.46h53.52a16.1,16.1,0,0,0,16.17-15.91v-.16A48.87,48.87,0,0,0,343,78.11Zm-73.16,0a25.06,25.06,0,0,1,5.65-8.67,21.23,21.23,0,0,1,3-2.51,24.68,24.68,0,0,1,29.55.17,23.73,23.73,0,0,1,2.72,2.34,24.86,24.86,0,0,1,5.53,8.67Z"
            />
            <path
              fill={mainColor}
              d="M233.73,36.21H211V20.46h0a13.05,13.05,0,0,0-13-13h0a13.05,13.05,0,0,0-13.05,13.05h0l.24,74.95a51.63,51.63,0,0,0,33.12,41.78A13,13,0,0,0,235.82,125h0a12.88,12.88,0,0,0-8.39-12.15A25,25,0,0,1,211,91c0-.21,0-3.72,0-9.18h0c0-11.37,0-19.63,0-19.63h22.69a13,13,0,0,0,13-13h0A13,13,0,0,0,233.73,36.21Z"
            />
            <circle fill={detailCotor} cx="365.73" cy="13.93" r="13.93" />
            <circle fill={detailCotor} cx="126.55" cy="87.25" r="13.93" />
          </g>
        </g>
      </svg>
    )
  }
}
