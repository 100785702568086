import { hotjar } from 'react-hotjar'
import ReactGA from 'react-ga'

export class Analytics {
  // 1571527 - https://dev-cotei-mega
  // 1571320 - https://cotei.mega.com.br/
  private startHotjar() {
    hotjar.initialize(1571320, 6)
  }

  private startGoogleAnalytics(location: string) {
    // UA-152865449-1 - https://dev-cotei-mega
    // UA-152782810-1 - https://cotei.mega.com.br/
    ReactGA.initialize('UA-152782810-1')
    ReactGA.pageview(location)
  }

  public initialezeAnalytics(location: string) {
    this.startHotjar()
    this.startGoogleAnalytics(location)
  }
}
const analytics = new Analytics()
export default analytics
