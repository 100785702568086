import { observable } from 'mobx'
import { CotacaoCloud, StatusCotacaoCloud } from '../shared/common/models/cotacaoCloud'
import { IFiliais } from 'shared/common/models/CompradorCloud'
import { IStatusQuoteFilter } from 'services/cotacaoCompradorService'

export class CotacaoCompradorStore {
  @observable
  public cotacaoComprador: CotacaoCloud[] = []

  @observable
  public selectedSubsidiary: IFiliais[] = []

  @observable
  public quoteNumber: number = null

  @observable
  public statusQuoteFilter: IStatusQuoteFilter[] = [
    {
      status: StatusCotacaoCloud.Aberta,
      isChecked: false,
      statusName: 'Aberta',
      statusQuoteFiltered: false,
    },
    {
      status: StatusCotacaoCloud.Encerrada,
      isChecked: false,
      statusName: 'Encerrada',
      statusQuoteFiltered: false,
    },
  ]

  @observable
  public initialDate = ''

  @observable
  public finalDate = ''

  @observable
  public isFiltered = {
    selectedSubsidiaryFiltered: false,
    quoteNumberFiltered: false,
    initialDateFiltered: false,
    finalDateFiltered: false,
  }
}

export const cotacaoCompradorStore = new CotacaoCompradorStore()
