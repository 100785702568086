import { createStyles } from '@material-ui/core/styles'

export const styles = () =>
  createStyles({
    button: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: 0,
      textAlign: 'center',
      borderRadius: '25px',
    },
    buttonSecondary: {
      background: '#33AADF',
      color: '#F1F3F4',
    },
    checkBoxLabel: {
      color: '#505050',
      margin: 0,
    },
  })
