import { observable } from 'mobx'
import {
  IQuotesSent,
  ISuppliersQuoted,
  IItemsQuoted,
  IQuotedResponseTime,
  IQueriesByQuotes,
  IAverageQuoteDiscount,
  ITopRatedSuppliers,
  ILeastResponsiveSuppliers,
  ISuppliersWithBetterDeliveries,
  IQuotationAnswer,
  IMoreResponsiveSuppliers,
  IIsDashboardLoading,
} from 'shared/common/models/DashboardCloud'

export class DashboardStore {
  @observable
  public quotesSent: IQuotesSent = {} as IQuotesSent

  @observable
  public suppliersQuoted: ISuppliersQuoted = {} as ISuppliersQuoted

  @observable
  public itemsQuoted: IItemsQuoted = {} as IItemsQuoted

  @observable
  public quotedResponseTime: IQuotedResponseTime = {} as IQuotedResponseTime

  @observable
  public queriesByQuotes: IQueriesByQuotes = {} as IQueriesByQuotes

  @observable
  public averageQuoteDiscount: IAverageQuoteDiscount =
    {} as IAverageQuoteDiscount

  @observable
  public topRatedSuppliers: ITopRatedSuppliers[] = [] as ITopRatedSuppliers[]

  @observable
  public leastResponsiveSuppliers: ILeastResponsiveSuppliers[] =
    [] as ILeastResponsiveSuppliers[]

  @observable
  public suppliersWithBetterDeliveries: ISuppliersWithBetterDeliveries[] =
    [] as ISuppliersWithBetterDeliveries[]

  @observable
  public quotationAnswer: IQuotationAnswer = {} as IQuotationAnswer

  @observable
  public moreResponsiveSuppliers: IMoreResponsiveSuppliers[] =
    [] as IMoreResponsiveSuppliers[]

  @observable
  public fromDate = ''

  @observable
  public toDate = ''

  @observable
  public isDashboardLoading: IIsDashboardLoading = {
    moreResponsiveSuppliers: true,
    quotationAnswer: true,
    suppliersWithBetterDeliveries: true,
    leastResponsiveSuppliers: true,
    topRatedSuppliers: true,
    queriesByQuotes: true,
    quotedResponseTime: true,
    itemsQuoted: true,
    suppliersQuoted: true,
    averageQuoteDiscount: true,
    quotesSent: true,
    downloadExcel: false,
  }

  dashboardStore: ISuppliersQuoted[]
}

export const dashboardStore = new DashboardStore()
